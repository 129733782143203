@import url('https://fonts.googleapis.com/css?family=Lobster:regular,bold,italic&subset=latin,latin-ext');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-bg: #242526;
  --secondary-bg: #fff;
  --primary-text-color: #555;
  --secondary-text-color: #cecece;
  --border-radius: 8px;
  --speed: 500ms;
}

/** Home page **/

body {
  background-color: #ffffff;
  font-family: Lobster;
  margin: 0;
  width: 100%;
  height: 100%;
}
footer {
  background-color: #ffcada;
  color: black;
  position: fixed;
  bottom: 0px;
  opacity: 1;
  margin-top: 10px;
  width: 100%;
  display: flex;
  height: 4.5rem;
}

button {
  background-color: white;
  font-family: Lobster;
  border: none;
  padding: 15px, 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;

  transform: scaleX(1);
  -webkit-transition: background 0.5s;
  border-radius: 15%;
}

button:hover {
  background-color: #ffcada;
}

#header {
  background-color: #ffcada;
  position: relative;
  display: flex;
  width: 100%;
}

#main-title {
  position: absolute;
  margin-left: 5%;
  margin-top: 10px;
  font-size: xx-large;
}

#nav-header {
  position: sticky;
  display: flex;
  width: auto;
  left: 70%;
}

#nav-header ul {
  list-style: none;
  display: flex;
}

#nav-header li {
  font-size: x-large;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 5px;
  display: inline;
}

#bio {
  background-color: #ffcada;
  font-size: x-large;
}

.nav-textcolour {
  color: black;
}
footer #contact {
  left: 20%;
  width: fit-content;
}

footer #rights {
  left: 60%;
  width: fit-content;
}

.footer-container {
  position: absolute;

  font-size: medium;
}

.li-underline::after {
  position: relative;
  content: '';
  display: block;
  height: 3px;
  width: 3rem;
  background-color: black;
  transform-origin: right bottom;
  transform: scaleX(0);
  transition: transform 0.3s linear;
}
.li-underline:hover::after,
.li-underline:active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s linear;
}

/*Ilustations*/
.img-ilus {
  position: relative;
  width: 600px;
  display: inline;
  border-radius: 15px;
  margin-top: 5em;
  margin-right: 3em;
  margin-left: 10em;
  margin-bottom: 5em;
}

.image-div {
  width: 90%;
  margin-top: 5em;
  margin-left: 5em;
  margin-bottom: 5em;
}

.img-ilus:hover {
  transform: scale(1.3);
  transition: transform 0.2s;
}

.section-select {
  position: relative;
  left: 45%;
  display: inline;
}

.loading {
  position: relative;
  left: 45%;
  margin-top: 30px;
}

@media only screen and (max-width: 360px) {
  #header {
    background-color: #ffcada;
    position:relative;
    display: flex;
    width: 100%;
  }
  #main-title {
    position: absolute;
    margin-left: 5%;
    margin-top: 3px;
    font-size: medium;
  }
  footer {
    background-color: #ffcada;
    color: black;
    position: fixed;
    display:block;
    bottom: 0px;
    width: 100%;
    height: 4.5rem;
  }
  footer #contact {
    right: 1px;
    width: fit-content;
  }

  footer #rights {
    display: inline;
    width: fit-content;
  }
  .footer-container {
    position: relative;

    font-size: small;
  }

  .img-ilus {
    width: 250px;
    margin-top: 10%;
  }
  .image-div {
    margin-top: 110px;
    position: relative;
    width: fit-content;
    right: 50%;
  }
  .section-select {
    font-size: medium;
    left: 45%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .loading {
    left: 60%;
  }
}
