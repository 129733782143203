.dropdown {
  position: relative;
  left: 70%;
}

.iconpos {
  position: static;
  left: 90%;
}

@keyframes drop {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
  }
}

#ddmenu {
  position: absolute;
  background-color: gray;
  opacity: 0.8;
  border-radius: 5px;
  animation: 0.5s ease-out 0s 1 drop;
}

#ddel {
  opacity: 1;
  font-size: large;
  margin: 5px;
}
